<!--
 * @Author: xwn 3299704183@qq.com
 * @Date: 2023-01-06 17:03:13
 * @LastEditors: lyx lllyx123456@163.com
 * @LastEditTime: 2023-01-09 20:12:07
 * @FilePath: \cloud_campus_Front-end\school_end\src\views\SManagerOaMeetingOnline.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <!-- 智慧管理/线下会议 -->
    <s-manager-oa-meeting-online-wrapper></s-manager-oa-meeting-online-wrapper>
</template>
<script>
import SManagerOaMeetingOnlineWrapper from "../components/scrollWrapper/SManagerOaMeetingOnlineWrapper.vue";

export default {
    name: "SManagerOaMeetingOnline",
    components:{SManagerOaMeetingOnlineWrapper}
}
</script>

<style scoped>

</style>
