<!-- 智慧管理/德育管理/生涯规划 -->
<template>
    <Building />
</template>
<script>
import Building from './Building.vue';
export default {
    name: "SManagerEdulifePlan",
    components: {
        Building
    }
}
</script>