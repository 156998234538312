<!-- 智慧安全/安全教育 -->
<template>
    <Building />
</template>
<script>
    import Building from './Building.vue';
    export default {
        name: "SSafeEdu",
        components: {
            Building
        }
    }
</script>