<!-- 智慧管理/协同办公/会议管理/会议分析 -->
<template>
    <KeepAliveRouterView />
</template>
<script>
import MeetingList from "../components/scrollWrapper/MeetingList"
import MeetingInfo from "../components/scrollWrapper/MeetingInfo"
export default {
    name: "SManagerOaMeeting",
    components: {
        MeetingList,
        MeetingInfo
    },
    data() {
        return {
            meetingId: ""
        }
    },
    methods: {
        /**
         * @Description: 切换组件
         * @DoWhat: 接收子组件传递的会议 ID 参数，并赋值到 meetingId
         * @UseScenarios: 会议列表和详情组件切换时使用
         * @Attention:
         * @Author: 马枫茂
         * @Date: 2022-11-08 16:00:13
         */
        switchPage(page) {
            this.meetingId = page
        }
    }
}
</script>
