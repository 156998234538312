<!--
 会议管理/教研出勤
-->
<template>
    <s-manager-oa-meetingAttendance-scroll-wrapper></s-manager-oa-meetingAttendance-scroll-wrapper>
</template>
<script>
import SManagerOaMeetingAttendanceScrollWrapper from '@/components/scrollWrapper/SManagerOaMeetingAttendance.vue';
export default {
    name: "SManagerOaMeetingAttendance",
    components: {
        SManagerOaMeetingAttendanceScrollWrapper
    }
}
</script>
