
<template>
    <SManagerHeadSetting></SManagerHeadSetting>
</template>
<script>
import SManagerHeadSetting from "@/components/scrollWrapper/SManagerHeadSetting"
export default{
    components:{
        SManagerHeadSetting
    }
}
</script>
