<!-- 智慧管理/资产管理 -->
<template>
    <KeepAliveRouterView />
</template>

<script>
export default {
    name: "SManagerResource"
}
</script>

<style scoped>

</style>