<template>
    <div>
        <component @change="change" :is="curCom" :mode="mode" :id="id"></component>
    </div>
</template>

<script>

export default {
    components: {
        SManagerMyReceiveList: () => import(/* webpackChunkName:'SManagerMyReceiveList' */"@/components/scrollWrapper/SManagerMyReceiveList"),
		SManagerMyReceiveFeedback: () => import(/* webpackChunkName:'SManagerMyReceiveFeedback' */"@/components/scrollWrapper/SManagerMyReceiveFeedback"),
    },
    data() {
        return {
            // list展示列表页面；feedback展示反馈页
            mode: "list",
            id:'',
            show: true,
            curCom:'SManagerMyReceiveList',
            comps: [
                'SManagerMyReceiveList',
                'SManagerMyReceiveFeedback',
            ],
        };
    },
    created() {
    },
    mounted() {
        this.change(this.mode)
    },
    methods: {
        change(mode, id) {
            this.mode = mode;
            this.id = id
            console.log('-----id -----',id)
            if(this.mode === 'list'){
                this.curCom = 'SManagerMyReceiveList'
                this.setGlobalPageType('list')
            }else{
                this.curCom = 'SManagerMyReceiveFeedback'
                this.setGlobalPageType('detail')
            }
        },
        setGlobalPageType (type) {
            this.type = type
            this.$eventDispatch('setGlobalPageType', type)
        },
    },
};
</script>
<style lang="scss" scoped>
</style>
